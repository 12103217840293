export const KATAKANAS_DATA = [
  {
    kana: "ア",
    romaji: "a",
    type: "gojuuon",
  },
  {
    kana: "イ",
    romaji: "i",
    type: "gojuuon",
  },
  {
    kana: "ウ",
    romaji: "u",
    type: "gojuuon",
  },
  {
    kana: "エ",
    romaji: "e",
    type: "gojuuon",
  },
  {
    kana: "オ",
    romaji: "o",
    type: "gojuuon",
  },
  {
    kana: "カ",
    romaji: "ka",
    type: "gojuuon",
  },
  {
    kana: "キ",
    romaji: "ki",
    type: "gojuuon",
  },
  {
    kana: "ク",
    romaji: "ku",
    type: "gojuuon",
  },
  {
    kana: "ケ",
    romaji: "ke",
    type: "gojuuon",
  },
  {
    kana: "コ",
    romaji: "ko",
    type: "gojuuon",
  },
  {
    kana: "サ",
    romaji: "sa",
    type: "gojuuon",
  },
  {
    kana: "シ",
    romaji: "shi",
    type: "gojuuon",
  },
  {
    kana: "ス",
    romaji: "su",
    type: "gojuuon",
  },
  {
    kana: "セ",
    romaji: "se",
    type: "gojuuon",
  },
  {
    kana: "ソ",
    romaji: "so",
    type: "gojuuon",
  },
  {
    kana: "タ",
    romaji: "ta",
    type: "gojuuon",
  },
  {
    kana: "チ",
    romaji: "chi",
    type: "gojuuon",
  },
  {
    kana: "ツ",
    romaji: "tsu",
    type: "gojuuon",
  },
  {
    kana: "テ",
    romaji: "te",
    type: "gojuuon",
  },
  {
    kana: "ト",
    romaji: "to",
    type: "gojuuon",
  },
  {
    kana: "ナ",
    romaji: "na",
    type: "gojuuon",
  },
  {
    kana: "ニ",
    romaji: "ni",
    type: "gojuuon",
  },
  {
    kana: "ヌ",
    romaji: "nu",
    type: "gojuuon",
  },
  {
    kana: "ネ",
    romaji: "ne",
    type: "gojuuon",
  },
  {
    kana: "ノ",
    romaji: "no",
    type: "gojuuon",
  },
  {
    kana: "ハ",
    romaji: "ha",
    type: "gojuuon",
  },
  {
    kana: "ヒ",
    romaji: "hi",
    type: "gojuuon",
  },
  {
    kana: "フ",
    romaji: "hu",
    type: "gojuuon",
  },
  {
    kana: "ヘ",
    romaji: "he",
    type: "gojuuon",
  },
  {
    kana: "ホ",
    romaji: "ho",
    type: "gojuuon",
  },
  {
    kana: "マ",
    romaji: "ma",
    type: "gojuuon",
  },
  {
    kana: "ミ",
    romaji: "mi",
    type: "gojuuon",
  },
  {
    kana: "ム",
    romaji: "mu",
    type: "gojuuon",
  },
  {
    kana: "メ",
    romaji: "me",
    type: "gojuuon",
  },
  {
    kana: "モ",
    romaji: "mo",
    type: "gojuuon",
  },
  {
    kana: "ヤ",
    romaji: "ya",
    type: "gojuuon",
  },
  {
    kana: "ユ",
    romaji: "yu",
    type: "gojuuon",
  },
  {
    kana: "ヨ",
    romaji: "yo",
    type: "gojuuon",
  },
  {
    kana: "ラ",
    romaji: "ra",
    type: "gojuuon",
  },
  {
    kana: "リ",
    romaji: "ri",
    type: "gojuuon",
  },
  {
    kana: "ル",
    romaji: "ru",
    type: "gojuuon",
  },
  {
    kana: "レ",
    romaji: "re",
    type: "gojuuon",
  },
  {
    kana: "ロ",
    romaji: "ro",
    type: "gojuuon",
  },
  {
    kana: "ワ",
    romaji: "wa",
    type: "gojuuon",
  },
  {
    kana: "ヲ",
    romaji: "wo",
    type: "gojuuon",
  },
  {
    kana: "ン",
    romaji: "n",
    type: "gojuuon",
  },
  {
    kana: "ガ",
    romaji: "ga",
    type: "dakuon",
  },
  {
    kana: "ギ",
    romaji: "gi",
    type: "dakuon",
  },
  {
    kana: "グ",
    romaji: "gu",
    type: "dakuon",
  },
  {
    kana: "ゲ",
    romaji: "ge",
    type: "dakuon",
  },
  {
    kana: "ゴ",
    romaji: "go",
    type: "dakuon",
  },
  {
    kana: "ザ",
    romaji: "za",
    type: "dakuon",
  },
  {
    kana: "ジ",
    romaji: "ji",
    type: "dakuon",
  },
  {
    kana: "ズ",
    romaji: "zu",
    type: "dakuon",
  },
  {
    kana: "ゼ",
    romaji: "ze",
    type: "dakuon",
  },
  {
    kana: "ゾ",
    romaji: "zo",
    type: "dakuon",
  },
  {
    kana: "ダ",
    romaji: "da",
    type: "dakuon",
  },
  {
    kana: "ヂ",
    romaji: "ji",
    type: "dakuon",
  },
  {
    kana: "ヅ",
    romaji: "zu",
    type: "dakuon",
  },
  {
    kana: "デ",
    romaji: "de",
    type: "dakuon",
  },
  {
    kana: "ド",
    romaji: "do",
    type: "dakuon",
  },
  {
    kana: "バ",
    romaji: "ba",
    type: "dakuon",
  },
  {
    kana: "ビ",
    romaji: "bi",
    type: "dakuon",
  },
  {
    kana: "ブ",
    romaji: "bu",
    type: "dakuon",
  },
  {
    kana: "ベ",
    romaji: "be",
    type: "dakuon",
  },
  {
    kana: "ボ",
    romaji: "bo",
    type: "dakuon",
  },
  {
    kana: "パ",
    romaji: "pa",
    type: "handakuon",
  },
  {
    kana: "ピ",
    romaji: "pi",
    type: "handakuon",
  },
  {
    kana: "プ",
    romaji: "pu",
    type: "handakuon",
  },
  {
    kana: "ペ",
    romaji: "pe",
    type: "handakuon",
  },
  {
    kana: "ポ",
    romaji: "po",
    type: "handakuon",
  },
  {
    kana: "ッ",
    romaji: "(pause)",
    type: "sokuon",
  },
  {
    kana: "キャ",
    romaji: "kya",
    type: "youon",
  },
  {
    kana: "キュ",
    romaji: "kyu",
    type: "youon",
  },
  {
    kana: "キョ",
    romaji: "kyo",
    type: "youon",
  },
  {
    kana: "シャ",
    romaji: "sha",
    type: "youon",
  },
  {
    kana: "シュ",
    romaji: "shu",
    type: "youon",
  },
  {
    kana: "ショ",
    romaji: "sho",
    type: "youon",
  },
  {
    kana: "チャ",
    romaji: "cha",
    type: "youon",
  },
  {
    kana: "チュ",
    romaji: "chu",
    type: "youon",
  },
  {
    kana: "チョ",
    romaji: "cho",
    type: "youon",
  },
  {
    kana: "ニャ",
    romaji: "nya",
    type: "youon",
  },
  {
    kana: "ニュ",
    romaji: "nyu",
    type: "youon",
  },
  {
    kana: "ニョ",
    romaji: "nyo",
    type: "youon",
  },
  {
    kana: "ヒャ",
    romaji: "hya",
    type: "youon",
  },
  {
    kana: "ヒュ",
    romaji: "hyu",
    type: "youon",
  },
  {
    kana: "ヒョ",
    romaji: "hyo",
    type: "youon",
  },
  {
    kana: "ミャ",
    romaji: "mya",
    type: "youon",
  },
  {
    kana: "ミュ",
    romaji: "myu",
    type: "youon",
  },
  {
    kana: "ミョ",
    romaji: "myo",
    type: "youon",
  },
  {
    kana: "リャ",
    romaji: "rya",
    type: "youon",
  },
  {
    kana: "リュ",
    romaji: "ryu",
    type: "youon",
  },
  {
    kana: "リョ",
    romaji: "ryo",
    type: "youon",
  },
  {
    kana: "ギャ",
    romaji: "gya",
    type: "youon",
  },
  {
    kana: "ギュ",
    romaji: "gyu",
    type: "youon",
  },
  {
    kana: "ギョ",
    romaji: "gyo",
    type: "youon",
  },
  {
    kana: "ジャ",
    romaji: "ja",
    type: "youon",
  },
  {
    kana: "ジュ",
    romaji: "ju",
    type: "youon",
  },
  {
    kana: "ジョ",
    romaji: "jo",
    type: "youon",
  },
  {
    kana: "ビャ",
    romaji: "bya",
    type: "youon",
  },
  {
    kana: "ビュ",
    romaji: "byu",
    type: "youon",
  },
  {
    kana: "ビョ",
    romaji: "byo",
    type: "youon",
  },
  {
    kana: "ピャ",
    romaji: "pya",
    type: "youon",
  },
  {
    kana: "ピュ",
    romaji: "pyu",
    type: "youon",
  },
  {
    kana: "ピョ",
    romaji: "pyo",
    type: "youon",
  },
]
