export const HIRGANAS_DATA = [
  {
    kana: "あ",
    romaji: "a",
    type: "gojuuon",
  },
  {
    kana: "い",
    romaji: "i",
    type: "gojuuon",
  },
  {
    kana: "う",
    romaji: "u",
    type: "gojuuon",
  },
  {
    kana: "え",
    romaji: "e",
    type: "gojuuon",
  },
  {
    kana: "お",
    romaji: "o",
    type: "gojuuon",
  },
  {
    kana: "か",
    romaji: "ka",
    type: "gojuuon",
  },
  {
    kana: "き",
    romaji: "ki",
    type: "gojuuon",
  },
  {
    kana: "く",
    romaji: "ku",
    type: "gojuuon",
  },
  {
    kana: "け",
    romaji: "ke",
    type: "gojuuon",
  },
  {
    kana: "こ",
    romaji: "ko",
    type: "gojuuon",
  },
  {
    kana: "さ",
    romaji: "sa",
    type: "gojuuon",
  },
  {
    kana: "し",
    romaji: "shi",
    type: "gojuuon",
  },
  {
    kana: "す",
    romaji: "su",
    type: "gojuuon",
  },
  {
    kana: "せ",
    romaji: "se",
    type: "gojuuon",
  },
  {
    kana: "そ",
    romaji: "so",
    type: "gojuuon",
  },
  {
    kana: "た",
    romaji: "ta",
    type: "gojuuon",
  },
  {
    kana: "ち",
    romaji: "chi",
    type: "gojuuon",
  },
  {
    kana: "つ",
    romaji: "tsu",
    type: "gojuuon",
  },
  {
    kana: "て",
    romaji: "te",
    type: "gojuuon",
  },
  {
    kana: "と",
    romaji: "to",
    type: "gojuuon",
  },
  {
    kana: "な",
    romaji: "na",
    type: "gojuuon",
  },
  {
    kana: "に",
    romaji: "ni",
    type: "gojuuon",
  },
  {
    kana: "ぬ",
    romaji: "nu",
    type: "gojuuon",
  },
  {
    kana: "ね",
    romaji: "ne",
    type: "gojuuon",
  },
  {
    kana: "の",
    romaji: "no",
    type: "gojuuon",
  },
  {
    kana: "は",
    romaji: "ha",
    type: "gojuuon",
  },
  {
    kana: "ひ",
    romaji: "hi",
    type: "gojuuon",
  },
  {
    kana: "ふ",
    romaji: "hu",
    type: "gojuuon",
  },
  {
    kana: "へ",
    romaji: "he",
    type: "gojuuon",
  },
  {
    kana: "ほ",
    romaji: "ho",
    type: "gojuuon",
  },
  {
    kana: "ま",
    romaji: "ma",
    type: "gojuuon",
  },
  {
    kana: "み",
    romaji: "mi",
    type: "gojuuon",
  },
  {
    kana: "む",
    romaji: "mu",
    type: "gojuuon",
  },
  {
    kana: "め",
    romaji: "me",
    type: "gojuuon",
  },
  {
    kana: "も",
    romaji: "mo",
    type: "gojuuon",
  },
  {
    kana: "や",
    romaji: "ya",
    type: "gojuuon",
  },
  {
    kana: "ゆ",
    romaji: "yu",
    type: "gojuuon",
  },
  {
    kana: "よ",
    romaji: "yo",
    type: "gojuuon",
  },
  {
    kana: "ら",
    romaji: "ra",
    type: "gojuuon",
  },
  {
    kana: "り",
    romaji: "ri",
    type: "gojuuon",
  },
  {
    kana: "る",
    romaji: "ru",
    type: "gojuuon",
  },
  {
    kana: "れ",
    romaji: "re",
    type: "gojuuon",
  },
  {
    kana: "ろ",
    romaji: "ro",
    type: "gojuuon",
  },
  {
    kana: "わ",
    romaji: "wa",
    type: "gojuuon",
  },
  {
    kana: "を",
    romaji: "wo",
    type: "gojuuon",
  },
  {
    kana: "ん",
    romaji: "n",
    type: "gojuuon",
  },
  {
    kana: "が",
    romaji: "ga",
    type: "dakuon",
  },
  {
    kana: "ぎ",
    romaji: "gi",
    type: "dakuon",
  },
  {
    kana: "ぐ",
    romaji: "gu",
    type: "dakuon",
  },
  {
    kana: "げ",
    romaji: "ge",
    type: "dakuon",
  },
  {
    kana: "ご",
    romaji: "go",
    type: "dakuon",
  },
  {
    kana: "ざ",
    romaji: "za",
    type: "dakuon",
  },
  {
    kana: "じ",
    romaji: "ji",
    type: "dakuon",
  },
  {
    kana: "ず",
    romaji: "zu",
    type: "dakuon",
  },
  {
    kana: "ぜ",
    romaji: "ze",
    type: "dakuon",
  },
  {
    kana: "ぞ",
    romaji: "zo",
    type: "dakuon",
  },
  {
    kana: "だ",
    romaji: "da",
    type: "dakuon",
  },
  {
    kana: "ぢ",
    romaji: "ji",
    type: "dakuon",
  },
  {
    kana: "づ",
    romaji: "zu",
    type: "dakuon",
  },
  {
    kana: "で",
    romaji: "de",
    type: "dakuon",
  },
  {
    kana: "ど",
    romaji: "do",
    type: "dakuon",
  },
  {
    kana: "ば",
    romaji: "ba",
    type: "dakuon",
  },
  {
    kana: "び",
    romaji: "bi",
    type: "dakuon",
  },
  {
    kana: "ぶ",
    romaji: "bu",
    type: "dakuon",
  },
  {
    kana: "べ",
    romaji: "be",
    type: "dakuon",
  },
  {
    kana: "ぼ",
    romaji: "bo",
    type: "dakuon",
  },
  {
    kana: "ぱ",
    romaji: "pa",
    type: "handakuon",
  },
  {
    kana: "ぴ",
    romaji: "pi",
    type: "handakuon",
  },
  {
    kana: "ぷ",
    romaji: "pu",
    type: "handakuon",
  },
  {
    kana: "ぺ",
    romaji: "pe",
    type: "handakuon",
  },
  {
    kana: "ぽ",
    romaji: "po",
    type: "handakuon",
  },
  {
    kana: "っ",
    romaji: "(pause)",
    type: "sokuon",
  },
  {
    kana: "きゃ",
    romaji: "kya",
    type: "youon",
  },
  {
    kana: "きゅ",
    romaji: "kyu",
    type: "youon",
  },
  {
    kana: "きょ",
    romaji: "kyo",
    type: "youon",
  },
  {
    kana: "しゃ",
    romaji: "sha",
    type: "youon",
  },
  {
    kana: "しゅ",
    romaji: "shu",
    type: "youon",
  },
  {
    kana: "しょ",
    romaji: "sho",
    type: "youon",
  },
  {
    kana: "ちゃ",
    romaji: "cha",
    type: "youon",
  },
  {
    kana: "ちゅ",
    romaji: "chu",
    type: "youon",
  },
  {
    kana: "ちょ",
    romaji: "cho",
    type: "youon",
  },
  {
    kana: "にゃ",
    romaji: "nya",
    type: "youon",
  },
  {
    kana: "にゅ",
    romaji: "nyu",
    type: "youon",
  },
  {
    kana: "にょ",
    romaji: "nyo",
    type: "youon",
  },
  {
    kana: "ひゃ",
    romaji: "hya",
    type: "youon",
  },
  {
    kana: "ひゅ",
    romaji: "hyu",
    type: "youon",
  },
  {
    kana: "ひょ",
    romaji: "hyo",
    type: "youon",
  },
  {
    kana: "みゃ",
    romaji: "mya",
    type: "youon",
  },
  {
    kana: "みゅ",
    romaji: "myu",
    type: "youon",
  },
  {
    kana: "みょ",
    romaji: "myo",
    type: "youon",
  },
  {
    kana: "りゃ",
    romaji: "rya",
    type: "youon",
  },
  {
    kana: "りゅ",
    romaji: "ryu",
    type: "youon",
  },
  {
    kana: "りょ",
    romaji: "ryo",
    type: "youon",
  },
  {
    kana: "ぎゃ",
    romaji: "gya",
    type: "youon",
  },
  {
    kana: "ぎゅ",
    romaji: "gyu",
    type: "youon",
  },
  {
    kana: "ぎょ",
    romaji: "gyo",
    type: "youon",
  },
  {
    kana: "じゃ",
    romaji: "ja",
    type: "youon",
  },
  {
    kana: "じゅ",
    romaji: "ju",
    type: "youon",
  },
  {
    kana: "じょ",
    romaji: "jo",
    type: "youon",
  },
  {
    kana: "びゃ",
    romaji: "bya",
    type: "youon",
  },
  {
    kana: "びゅ",
    romaji: "byu",
    type: "youon",
  },
  {
    kana: "びょ",
    romaji: "byo",
    type: "youon",
  },
  {
    kana: "ぴゃ",
    romaji: "pya",
    type: "youon",
  },
  {
    kana: "ぴゅ",
    romaji: "pyu",
    type: "youon",
  },
  {
    kana: "ぴょ",
    romaji: "pyo",
    type: "youon",
  },
]
