import { Link } from "react-router-dom"

import Logo from "../Logo"
import styles from "./GameMenu.module.css"
export default function GameMenu() {
  return (
    <div className={styles.container}>
      <Logo />
      <ul>
        <Link to="/hiragana-game">
          <li className={styles.hiragana}>Hiraganas</li>
        </Link>
        <Link to="/katakana-game">
          <li className={styles.katakana}>Katakanas</li>
        </Link>
        <Link to="/kanji-game">
          <li className={styles.kanji}>Kanjis</li>
        </Link>
      </ul>
    </div>
  )
}
