import { StoreAction } from "../@types/store"

export const IS_GAME_READY = "IS_GAME_READY"
export function isGameReady(isGameReady: boolean): StoreAction {
  return {
    type: IS_GAME_READY,
    isGameReady,
  }
}

export const SET_HIRAGANAS = "SET_HIRAGANAS"
export function setHiraganas(hiraganas: string[]): StoreAction {
  return {
    type: SET_HIRAGANAS,
    hiraganas,
  }
}

export const SET_KATAKANAS = "SET_KATAKANAS"
export function setKatakanas(katakanas: string[]): StoreAction {
  return {
    type: SET_KATAKANAS,
    katakanas,
  }
}

export const START_TIMER = "START_TIMER"
export function startTimer(): StoreAction {
  return {
    type: START_TIMER,
    isTimerStarted: true,
  }
}

export const STOP_TIMER = "STOP_TIMER"
export function stopTimer(): StoreAction {
  return {
    type: STOP_TIMER,
    isTimerStarted: false,
  }
}
