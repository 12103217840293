import { Suspense } from "react"
import { Route, Routes } from "react-router-dom"
import { GradeEnum } from "./@types/Grade"
import "./App.css"
import GameMenu from "./modules/GameMenu"
import HiraganaGame from "./modules/Hiragana"
import Kanji from "./modules/Kanji"
import KanjiGame from "./modules/Kanji/KanjiGame/KanjiGame"
import KatakanaGame from "./modules/Katakana"

// use Data loading: https://reactrouter.com/en/main/start/overview#data-loading

function App() {
  return (
    <div className="App">
      <Routes>
        <Route index path="/" element={<GameMenu />} />
        <Route path="/game-menu" element={<GameMenu />} />
        <Route path="/hiragana-game" element={<HiraganaGame />} />
        <Route path="/katakana-game" element={<KatakanaGame />} />
        <Route path="/kanji-game" element={<Kanji />} />
        <Route
          path="/kanji-game/grade-1"
          element={
            <Suspense fallback={<span> Loading...</span>}>
              <KanjiGame grade={GradeEnum.Grade1} />
            </Suspense>
          }
          loader={() => {
            // data to be fetched and sent to useLoaderData hook
          }}
        />
        <Route
          path="/kanji-game/grade-2"
          element={
            <Suspense fallback={<span> Loading...</span>}>
              <KanjiGame grade={GradeEnum.Grade2} />
            </Suspense>
          }
          loader={() => {
            // data to be fetched and sent to useLoaderData hook
          }}
        />
        <Route
          path="/kanji-game/grade-3"
          element={
            <Suspense fallback={<span> Loading...</span>}>
              <KanjiGame grade={GradeEnum.Grade3} />
            </Suspense>
          }
          loader={() => {
            // data to be fetched and sent to useLoaderData hook
          }}
        />
        <Route
          path="/kanji-game/grade-4"
          element={
            <Suspense fallback={<span> Loading...</span>}>
              <KanjiGame grade={GradeEnum.Grade4} />
            </Suspense>
          }
          loader={() => {
            // data to be fetched and sent to useLoaderData hook
          }}
        />
        <Route
          path="/kanji-game/grade-5"
          element={
            <Suspense fallback={<span> Loading...</span>}>
              <KanjiGame grade={GradeEnum.Grade5} />
            </Suspense>
          }
          loader={() => {
            // data to be fetched and sent to useLoaderData hook
          }}
        />
        <Route
          path="/kanji-game/all"
          element={
            <Suspense fallback={<span> Loading...</span>}>
              <KanjiGame grade={GradeEnum.All} />
            </Suspense>
          }
          loader={() => {
            // data to be fetched and sent to useLoaderData hook
          }}
        />
      </Routes>
    </div>
  )
}

export default App
