import { Link } from "react-router-dom"
import styles from "./Kanji.module.css"
export default function Kanji() {
  return (
    <div className={styles.container}>
      <div className={styles.title}>difficulty</div>
      <ul>
        <Link to="/kanji-game/grade-1">
          <li className={styles["very-easy"]}>grade 1</li>
        </Link>
        <Link to="/kanji-game/grade-2">
          <li className={styles["easy"]}>grade 2</li>
        </Link>
        <Link to="/kanji-game/grade-3">
          <li className={styles["medium"]}>grade 3</li>
        </Link>
        <Link to="/kanji-game/grade-4">
          <li className={styles["hard"]}>grade 4</li>
        </Link>
        <Link to="/kanji-game/grade-5">
          <li className={styles["very-hard"]}>grade 5</li>
        </Link>
        <Link to="/kanji-game/all">
          <li className={styles["godlike"]}>all</li>
        </Link>
      </ul>
    </div>
  )
}
