import { Link } from "react-router-dom"
import { useDispatch } from "../../@store"
import { isGameReady } from "../../@store/action"
import styles from "./Logo.module.css"

export default function Logo() {
  const dispatch = useDispatch()
  const title = ["暗", "記", "す", "る", "!"]

  function resetAnimations() {
    dispatch(isGameReady(false))
  }

  return (
    <Link to="/" onClick={resetAnimations}>
      <div className={styles.logo}>
        <div className={styles["text-gradient"]}>
          {title.map((char, index) => {
            return <span key={index.toString()}>{char}</span>
          })}
        </div>
      </div>
    </Link>
  )
}
