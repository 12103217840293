import { useEffect, useState } from "react"
import { GradeEnum } from "../../../@types/Grade"
import { KanjiData } from "../../../@types/Kanji"
import KanjiCard from "../KanjiCard"
const loadKanjiData = () => import("../../../data/KANJIS.json")

type Props = {
  grade: GradeEnum
}

export default function KanjiGame({ grade }: Props) {
  const [kanjiData, setKanjiData] = useState<KanjiData>({})

  useEffect(() => {
    async function fetchData() {
      // Fetch the full JSON data file

      const data = (await loadKanjiData()) as unknown as KanjiData

      if (grade === GradeEnum.All) {
        setKanjiData(data)
      } else {
        // Filter the data based on the "grade" prop
        const filteredData = Object.keys(data).reduce((acc, key) => {
          const kanji = data[key]
          if (kanji.grade === grade) {
            acc[key] = kanji
          }
          return acc
        }, {} as KanjiData)

        // Set the filtered data in state
        setKanjiData(filteredData)
      }
    }

    fetchData()
  }, [grade])

  return (
    <div>
      {/* Render the filtered kanji data here */}
      {Object.keys(kanjiData).map((key) => {
        return <KanjiCard key={key} charKey={key} data={kanjiData} />
      })}
    </div>
  )
}
