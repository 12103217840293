import React, { useEffect, useRef, useState } from "react"
import hint from "../../assets/images/hint.svg"
import skip from "../../assets/images/skip.png"
import styles from "./Hint.module.css"
interface Props {
  romaji: string
  onChangeCharacter: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void
  onHintClick: (e: React.MouseEvent<Element, MouseEvent>) => void
}
export default function Hint({
  romaji,
  onChangeCharacter,
  onHintClick,
}: Props) {
  const [buttonClicked, setButtonClicked] = useState(false)
  const hintRef = useRef<HTMLDivElement | null>(null)
  const nextCharRef = useRef<HTMLDivElement | null>(null)

  function handleHintClick(event: React.MouseEvent) {
    setButtonClicked(true)
    onHintClick(event)
  }

  //looking for keyboard shortcuts (spacebar-> display hint)
  function handleSpacebarKeyDown(e: KeyboardEvent) {
    if (e.key === " " || e.code === "Space") {
      if (hintRef.current) {
        hintRef.current.click() //send click event to hint button
      }
    }
    if (e.key === "ArrowRight" || e.code === "ArrowRight") {
      if (nextCharRef.current) {
        nextCharRef.current.click() //send click event to hint button
      }
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleSpacebarKeyDown)
    return () => {
      document.removeEventListener("keydown", handleSpacebarKeyDown)
    }
  })

  useEffect(() => {
    setButtonClicked(false)
  }, [romaji])

  return (
    <div className={styles.container}>
      <div
        ref={nextCharRef}
        className={styles["change-char-button"]}
        onClick={onChangeCharacter}
      >
        <img alt="skip" src={skip} />
      </div>
      <div
        ref={hintRef}
        className={styles["hint-button"]}
        onClick={handleHintClick}
      >
        <img alt="hint" src={hint} />
      </div>
      <div
        className={styles["hint"]}
        style={{ visibility: buttonClicked ? "visible" : "hidden" }}
      >
        {romaji}
      </div>
    </div>
  )
}
