import {
  ChangeEvent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react"
import { GameType } from "../../@types/GameType"
import { JapaneseCharacter } from "../../@types/JapaneseCharacter"

import { HIRGANAS_DATA } from "../../data/HIRAGANAS"
import { KATAKANAS_DATA } from "../../data/KATAKANAS"
import Hint from "../Hint/Hint"
import Logo from "../Logo"
import styles from "./Game.module.css"
export interface Props {
  game: GameType
}

export default function Game({ game }: Props) {
  const [characters, setCharacters] =
    useState<JapaneseCharacter[]>(HIRGANAS_DATA)
  const [randomCharIndex, setRandomCharIndex] = useState(0)
  const [text, setText] = useState("")
  const [isAnswerCorrect, setIsAnswerCorrect] = useState<null | boolean>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const scrollViewRef = useRef<HTMLDivElement>(null)

  function handleChangeCharacter(e: React.MouseEvent<HTMLInputElement>) {
    setText("")
    setIsAnswerCorrect(null)
    setRandomCharIndex(Math.round(Math.random() * characters.length))
  }

  function handleHintClick(e: React.MouseEvent<Element, MouseEvent>) {
    setText(characters[randomCharIndex].romaji)
    setIsAnswerCorrect(false)
  }

  function handleTextChange(e: ChangeEvent<HTMLInputElement>) {
    setText(e.target.value.replace(/\s/g, "")) // remove spaces

    // if (isAnswerCorrect !== null) {
    //   setIsAnswerCorrect(null)
    // }
  }

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTop = 0
      scrollViewRef.current.style.overflowY = "hidden"
    }
  }

  const handleBlur = () => {
    if (scrollViewRef.current) {
      scrollViewRef.current.style.overflowY = "scroll"
    }
  }

  function handleSubmit(event: React.KeyboardEvent<HTMLElement>) {
    if (event.key === "Enter") {
      if (
        text.toLowerCase() === characters[randomCharIndex].romaji.toLowerCase()
      ) {
        setIsAnswerCorrect(true)

        setTimeout(() => {
          setText("")
          setIsAnswerCorrect(null)
          setRandomCharIndex(Math.floor(Math.random() * characters.length))
        }, 100)
      } else if (
        text.toLowerCase() !== characters[randomCharIndex].romaji.toLowerCase()
      ) {
        setIsAnswerCorrect(false)
      } else {
        setIsAnswerCorrect(null)
      }
    }
  }

  // Set the focus on the input element when the text state changes
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [text])

  useLayoutEffect(() => {
    switch (game) {
      case "hiragana":
        setCharacters(HIRGANAS_DATA)
        break
      case "katakana":
        setCharacters(KATAKANAS_DATA)
        break
      default:
        setCharacters(HIRGANAS_DATA)
        break
    }
  }, [game])

  useLayoutEffect(() => {
    // Add overflow: hidden to the body element when the component is mounted
    document.body.style.overflow = "hidden"

    // Remove overflow: hidden from the body element when the component is unmounted
    return () => {
      document.body.style.overflow = "auto"
    }
  }, [])

  //randomize character pick
  useEffect(() => {
    setRandomCharIndex(Math.round(Math.random() * characters.length))
  }, [characters.length])

  return (
    <div className={styles.container}>
      <Logo />
      <div
        className={styles.character}
        style={{
          width:
            characters[randomCharIndex].kana.length > 1 ? "600px" : "300px",
        }}
      >
        {characters[randomCharIndex].kana}
      </div>
      <div
        ref={scrollViewRef}
        className={styles["text-container"]}
        style={{ overflowY: "scroll" }}
      >
        <Hint
          onChangeCharacter={handleChangeCharacter}
          onHintClick={handleHintClick}
          romaji={characters[randomCharIndex].romaji}
        />

        <input
          autoComplete="off"
          ref={inputRef}
          onFocus={handleFocus}
          onBlur={handleBlur}
          style={{
            border: isAnswerCorrect
              ? `2px solid #00ce4c`
              : isAnswerCorrect === null
              ? `2px solid #9d9d9d`
              : `2px solid #cd0037`,
          }}
          spellCheck="false"
          type="text"
          id="romaji-text"
          disabled={isAnswerCorrect ? true : false}
          value={text}
          onChange={handleTextChange}
          onKeyDownCapture={handleSubmit}
        />
      </div>
      {/* <CountdownTimer timeLimit={config.timer.countdownTime} /> */}
    </div>
  )
}
