import { StoreAction } from "../@types/store"
import {
  IS_GAME_READY,
  SET_HIRAGANAS,
  SET_KATAKANAS,
  START_TIMER,
  STOP_TIMER,
} from "./action"

export interface StoreState {
  isGameReady: boolean
  isTimerStarted: boolean
  hiraganas: string[]
  katakanas: string[]
}

export const initialState: StoreState = {
  isGameReady: false,
  isTimerStarted: false,
  hiraganas: [],
  katakanas: [],
}

export default function reducer(
  state: StoreState,
  action: StoreAction
): StoreState {
  switch (action.type) {
    case IS_GAME_READY: {
      return {
        ...state,
        isGameReady: action.isGameReady as boolean,
      }
    }

    case SET_HIRAGANAS: {
      return {
        ...state,
        hiraganas: action.hiraganas as string[],
      }
    }

    case SET_KATAKANAS: {
      return {
        ...state,
        katakanas: action.katakanas as string[],
      }
    }

    case START_TIMER: {
      return {
        ...state,
        isTimerStarted: true,
      }
    }

    case STOP_TIMER: {
      return {
        ...state,
        isTimerStarted: false,
      }
    }

    default:
      return state
  }
}
