import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { StoreProvider } from "./@store"
import App from "./App"
import "./assets/styles/index.css"
import CustomCursor from "./components/CustomCursor"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <BrowserRouter>
    <StoreProvider>
      <CustomCursor />
      <App />
    </StoreProvider>
  </BrowserRouter>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()
