import { useStore } from "../@store"

export function useHiraganas(): string[] {
  const { state } = useStore()
  const { hiraganas } = state

  if (hiraganas.length === 0) {
    return []
  }

  return hiraganas
}

export function useKatakanas(): string[] {
  const { state } = useStore()
  const { katakanas } = state

  if (katakanas.length === 0) {
    return []
  }

  return katakanas
}

export function useIsGameReady(): boolean {
  const { state } = useStore()
  const { isGameReady } = state

  if (!isGameReady) {
    return false
  }

  return isGameReady
}

export function useIsTimerStarted(): boolean {
  const { state } = useStore()
  const { isTimerStarted } = state

  if (!isTimerStarted) {
    return false
  }

  return isTimerStarted
}
