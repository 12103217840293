import { GameType } from "../../@types/GameType"
import Countdown from "../../components/Countdown"
// import Countdown from "../../components/Countdown"
import { useIsGameReady } from "../../libs/store"
import Game from "../Game"
import styles from "./Hiragana.module.css"
export default function HiraganaGame() {
  const isGameReady = useIsGameReady()

  // return (
  //   <div className={styles.container}>{<Game game={GameType.Hiragana} />}</div>
  // )
  return (
    <div className={styles.container}>
      {!isGameReady && <Countdown nbSec={3} />}
      {isGameReady && <Game game={GameType.Hiragana} />}
    </div>
  )
}
