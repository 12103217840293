import { KanjiData } from "../../../@types/Kanji"
import styles from "./KanjiCard.module.css"

interface Props {
  charKey: string
  data: KanjiData
}
export default function KanjiCard({ charKey, data }: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.character}>{charKey}</div>
      <div className={styles["details-container"]}>
        <div className={styles["meaning-title"]}>MEANINGS</div>
        <div className={styles["meaning"]}>{data[charKey].meanings}</div>
        <div className={styles["reading-on-title"]}>ON (Chinese)</div>
        <div className={styles["reading-on"]}>{data[charKey].readings_on}</div>
        <div className={styles["reading-kun-title"]}>KUN(Native Japanese)</div>
        <div className={styles["reading-kun"]}>
          {data[charKey].readings_kun}
        </div>
        <div className={styles["radical-title"]}>RADICALS</div>
        <div className={styles["radical"]}>{data[charKey].wk_radicals}</div>
      </div>
    </div>
  )
}
