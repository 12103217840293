import { useEffect, useState } from "react"
import { useDispatch } from "../../@store"
import { isGameReady, startTimer } from "../../@store/action"
import styles from "./Countdown.module.css"

export interface Props {
  nbSec: number
}

export default function Countdown({ nbSec }: Props) {
  const dispatch = useDispatch()
  const [countdown, setCountdown] = useState(nbSec)

  // countdown management
  useEffect(() => {
    if (countdown === 0) return

    const id = setTimeout(() => {
      setCountdown(countdown - 1)

      if (countdown === 1) {
        dispatch(isGameReady(true))
        dispatch(startTimer())
      }
    }, 1000)

    return () => clearTimeout(id)
  }, [countdown, dispatch])

  return (
    <div className={styles.counter}>
      {countdown === 3 ? "Get Ready" : countdown === 2 ? "Steady" : "Go!"}
    </div>
  )
}
